import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Collapse, Button } from 'antd';
import { CustomPanelLine } from './CustomPanelLine';
import { SoftwareSetting } from '../../../../Models/SoftwareManager/SoftwareSettings';
import { serviceBundler, webSocketService } from '../../../../App';
import { WebApplicationSetting } from '../../../../Models/SoftwareManager/WebApplicationSetting';
import { AddWebApplicationConfig } from '../../forms/AddWebApplicationConfig';
import {
  PlusCircleOutlined,
  EditOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import ProgressWebApp from '../../timelines/ProgressWebApp';
import { WsPublishStatus } from '../../Models/WsPublishStatus';
import { WsPublishCommand } from '../../Models/WsPublishCommand';
import { PublishSoftwareType } from '../../Models/PublishSoftwareType';

const { Panel } = Collapse;

const WebAppPanel = forwardRef(
  (
    baseProps: { softwareSetting: SoftwareSetting; drawerOutput: Function },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      UpdateStatus(status: WsPublishStatus) {
        UpdateWebApiProgress(status);
      },

      ResetSteps() {},

      AddWebApplicationConfiguration() {
        AddWebApplicationConfiguration();
      },
    }));

    const [isModalOpenWebApi, setIsModalOpenWebApi] = useState(false);
    const [isModelEditModeWebApi, setIsModalEditModeWebApi] = useState(false);
    const [webApplicationSetting, setWebApplicationSetting] =
      useState<WebApplicationSetting>();
    const [softwareSetting, setSoftwareSetting] = useState<SoftwareSetting>(
      new SoftwareSetting(),
    );

    const ContentPanel = (item: WebApplicationSetting) => {
      return (
        <>
          <br></br>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={() => PublishCollection(item)}
          >
            Publiceren
          </Button>
          <ProgressWebApp ref={childRef}></ProgressWebApp>
        </>
      );
    };

    const AddWebApplicationConfiguration = () => {
      setWebApplicationSetting(undefined);
      setIsModalEditModeWebApi(false);
      setIsModalOpenWebApi(true);
    };

    const EditWebApplicationConfiguration = (item: WebApplicationSetting) => {
      setWebApplicationSetting(item);
      setIsModalEditModeWebApi(true);
      setIsModalOpenWebApi(true);
    };

    const HandleCancelFormWebApi = () => {
      setIsModalOpenWebApi(false);
    };

    const RenderDrawerContent = (item: WebApplicationSetting) => {
      baseProps.drawerOutput(ContentPanel(item));
    };

    const SafeSoftwareSetting = (props: SoftwareSetting) => {
      if (props.id !== undefined) {
        serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      } else {
        serviceBundler.softwareSettingService.AddSoftwareSetting(props);
      }
      setIsModalOpenWebApi(false);
    };

    const RemoveSoftwareSetting = (props: SoftwareSetting) => {
      serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      setIsModalOpenWebApi(false);
    };

    const PublishCollection = (props: WebApplicationSetting) => {
      if (baseProps.softwareSetting === undefined) {
        return;
      }

      var newPublishCommand = new WsPublishCommand(
        PublishSoftwareType.WsPublishWebApp,
      );
      newPublishCommand.SoftwareLabel =
        baseProps.softwareSetting?.softwareLabel;
      newPublishCommand.ItemLabel = props.webApplicationLabel;
      webSocketService.sendMessage(newPublishCommand);

      const node = childRef.current;
      node!.ResetSteps();
    };

    const childRef = useRef<any>();
    const UpdateWebApiProgress = (status: WsPublishStatus) => {
      const node = childRef.current;
      node!.UpdateStatus(status);
    };

    useEffect(() => {
      setSoftwareSetting(baseProps.softwareSetting);
    }, [softwareSetting]);

    return (
      <>
        <AddWebApplicationConfig
          allData={baseProps.softwareSetting?.webApplications}
          parentData={baseProps.softwareSetting}
          data={webApplicationSetting}
          open={isModalOpenWebApi}
          onCancel={HandleCancelFormWebApi}
          onSafe={SafeSoftwareSetting}
          onRemove={RemoveSoftwareSetting}
          onEdit={isModelEditModeWebApi}
        ></AddWebApplicationConfig>
        <Collapse>
          {softwareSetting.webApplications
            ? softwareSetting?.webApplications.map(
                (item: WebApplicationSetting, i) => (
                  <Panel
                    collapsible={'icon'}
                    header={CustomPanelLine(
                      item.webApplicationLabel,
                      <ArrowRightOutlined />,
                      <EditOutlined />,
                      RenderDrawerContent,
                      EditWebApplicationConfiguration,
                      item,
                    )}
                    key={i}
                  ></Panel>
                ),
              )
            : []}
        </Collapse>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => AddWebApplicationConfiguration()}
            style={{ marginTop: '10px', marginRight: '17px' }}
            type="primary"
            shape="circle"
            icon={<PlusCircleOutlined />}
          />
        </div>
      </>
    );
  },
);

export { WebAppPanel as WebApplicationPanel };
