import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Collapse, Button, Typography } from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { AddWebApiConfig } from '../../forms/AddWebApiConfig';
import ProgressWebApi from '../../timelines/ProgressWebApi';
import { CustomPanelLine } from './CustomPanelLine';
import { WebApiSetting } from '../../../../Models/SoftwareManager/WebApiSetting';
import { SoftwareSetting } from '../../../../Models/SoftwareManager/SoftwareSettings';
import { serviceBundler, webSocketService } from '../../../../App';
import { WsPublishStatus } from '../../Models/WsPublishStatus';
import { WsPublishCommand } from '../../Models/WsPublishCommand';
import { PublishSoftwareType } from '../../Models/PublishSoftwareType';

const { Panel } = Collapse;
const { Text } = Typography;

const WebApiPanel = forwardRef(
  (
    baseProps: { softwareSetting: SoftwareSetting; drawerOutput: Function },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      UpdateStatus(status: WsPublishStatus) {
        UpdateProgress(status);
      },

      ResetSteps() {},
    }));

    const [isModalOpenWebApi, setIsModalOpenWebApi] = useState(false);
    const [isModelEditModeWebApi, setIsModalEditModeWebApi] = useState(false);
    const [webApiSetting, setWebApiSetting] = useState<WebApiSetting>();
    const [softwareSetting, setSoftwareSetting] = useState<SoftwareSetting>(
      new SoftwareSetting(),
    );

    const ContentPanel = (item : WebApiSetting) => {
      return (
        <>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={() => PublishCollection(item)}
          >
            Publiceren
          </Button>
          <ProgressWebApi ref={childRef}></ProgressWebApi>
        </>
      );
    };

    const AddWebApiConfiguration = () => {
      setWebApiSetting(new WebApiSetting());
      setIsModalEditModeWebApi(false);
      setIsModalOpenWebApi(true);
    };

    const EditWebApiConfiguration = (item: WebApiSetting) => {
      setWebApiSetting(item);
      setIsModalEditModeWebApi(true);
      setIsModalOpenWebApi(true);
    };

    const HandleCancelFormWebApi = () => {
      setIsModalOpenWebApi(false);
    };

    const RenderDrawerContent = (item: WebApiSetting) => {
      baseProps.drawerOutput(ContentPanel(item));
    };

    const SafeSoftwareSetting = (props: SoftwareSetting) => {
      if (props.id !== undefined) {
        serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      } else {
        serviceBundler.softwareSettingService.AddSoftwareSetting(props);
      }
      setIsModalOpenWebApi(false);
    };

    const RemoveSoftwareSetting = (props: SoftwareSetting) => {
      serviceBundler.softwareSettingService.UpdateSoftwareSetting(props);
      setIsModalOpenWebApi(false);
    };

    const PublishCollection = (props: WebApiSetting) => {
      if (baseProps.softwareSetting === undefined) {
        return;
      }

      var newPublishCommand = new WsPublishCommand(PublishSoftwareType.WsPublishWebApi);
      newPublishCommand.SoftwareLabel =
        baseProps.softwareSetting?.softwareLabel;
      newPublishCommand.ItemLabel = props.webApiLabel;
      webSocketService.sendMessage(newPublishCommand);

      const node = childRef.current;
      node!.ResetSteps();
    };

    const childRef = useRef<any>();
    const UpdateProgress = (status: WsPublishStatus) => {
      const node = childRef.current;
      node!.UpdateStatus(status);
    };

    useEffect(() => {
      setSoftwareSetting(baseProps.softwareSetting);
    }, [softwareSetting]);

    return (
      <>
        <>
          <AddWebApiConfig
            allData={baseProps.softwareSetting?.webApis}
            parentData={baseProps.softwareSetting}
            data={webApiSetting}
            open={isModalOpenWebApi}
            onCancel={HandleCancelFormWebApi}
            onSafe={SafeSoftwareSetting}
            onRemove={RemoveSoftwareSetting}
            onEdit={isModelEditModeWebApi}
          ></AddWebApiConfig>
          <Collapse>
            {softwareSetting.webApis
              ? softwareSetting?.webApis.map((item: WebApiSetting, i) => (
                  <Panel
                    collapsible={'icon'}
                    header={CustomPanelLine(
                      item.webApiLabel,
                      <ArrowRightOutlined />,
                      <EditOutlined />,
                      RenderDrawerContent,
                      EditWebApiConfiguration,
                      item,
                    )}
                    key={i}
                  ></Panel>
                ))
              : []}
          </Collapse>
        </>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => AddWebApiConfiguration()}
            style={{ marginTop: '10px', marginRight: '17px' }}
            type="primary"
            shape="circle"
            icon={<PlusCircleOutlined />}
          />
        </div>
      </>
    );
  },
);

export { WebApiPanel };
