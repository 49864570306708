import { BehaviorSubject, Observable } from 'rxjs';
import { authService, serviceBundler } from '../App';
import { AxiosResponse } from 'axios';
import { BackupHelper } from '../Helpers/BackupHelper';
import { BackupConfig } from '../Models/Backup/BackupConfig';
import { Backup } from '../Models/Backup/Backup';

export class BackupService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public backupConfigs!: BackupConfig;
  public backups!: Array<Backup>;
  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
  }

  private async FetchItems() {
    var token = authService.GetToken();

    if (!this.FetchingReady) {
      this.FetchingReady = true;
      this.backupConfigs = await BackupHelper.GetBackupConfigs(token);
      this.backups = await BackupHelper.GetBackups(token);

      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject.asObservable();
  }

  public GetBackupConfigs() {
    return this.backupConfigs;
  }

  public GetBackups() {
    return this.backups;
  }

  public async UpdateBackup(item: BackupConfig) {
    var token = authService.GetToken();
    await BackupHelper.UpdateBackup(item, token);
  }
}
