import { BackupService } from './BackupService';
import { LanguageService } from './LanguageService';
import { SoftwareSettingService } from './SoftwareSettingService';
import { ThemeService } from './ThemeService';
import { UserService } from './UserService';
import { WebSocketService } from './WebSocketService';

class ServiceBundler {
  public themeService;
  
  public softwareSettingService;
  public userService;
  public backupService;
  public languageService;

  constructor(){
    this.themeService = new ThemeService();

    this.languageService = new LanguageService();

    this.softwareSettingService = new SoftwareSettingService();
    this.userService = new UserService();
    this.backupService = new BackupService();
  }
}

export { ServiceBundler };
