import { HttpHelper } from "./HttpHelper"
import { BackupConfig } from "../Models/Backup/BackupConfig"
import { Backup } from "../Models/Backup/Backup"
import { Language } from "../Models/Language/Language"

export abstract class LanguageHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "Language"
    
    public static async GetAvailableLanguages(){
        var urlForRequest = this.baseUrl  + "/AvailableLanguages"
        console.log(urlForRequest)
        return (await HttpHelper.GetRequestForApi<Language[]>(urlForRequest))
    }

    public static async GetLanguages(){
        var urlForRequest = this.baseUrl + "/LanguagesWithTranslations"
        return (await HttpHelper.GetRequestForApi<Language[]>(urlForRequest))
    }

    public static async AddLanguage(item: Language, token?: string){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<Language>(urlForRequest, item))
    }

    public static async UpdateLanguage(item: Language, token?: string){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.UpdateRequestForApi<Language>(urlForRequest, item))
    }

    public static async DeleteLanguage(item: Language, token?: string){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.DeleteRequestForApi(urlForRequest, item, token))
    }
}